import { getImageDimensions, SanityImageDimensions, SanityImageSource } from "@sanity/asset-utils";
import { SanityImageSrc } from "./SanityImageCore";

const fallbackValues: SanityImageDimensions = {
  width: 1,
  height: 1,
  aspectRatio: 1,
};

const imageHasCrop = <T extends SanityImageSrc>(image: SanityImageSrc): image is Required<Pick<T, "crop">> & T =>
  typeof image === "object" &&
  (image as any)["crop"]?._type === "sanity.imageCrop" &&
  (!!(image as any)["crop"].bottom ||
    !!(image as any)["crop"].left ||
    !!(image as any)["crop"].right ||
    !!(image as any)["crop"].top);

// If an image-asset is deleted in sanity studio the image field on the doucment (with alt-text etc) might still exist in the data, but will cause getImageDimensions to throw an error
export function safelyGetImageDimensions(image?: SanityImageSrc): SanityImageDimensions {
  if (!image) return fallbackValues;
  try {
    const { width, height, aspectRatio } = getImageDimensions(image as SanityImageSource);

    // getImageDimensions does not take crop into account
    if (imageHasCrop(image)) {
      const { left = 0, right = 0, bottom = 0, top = 0 } = image.crop ?? {};
      const widthWithCrop = width * (1 - left - right);
      const heightWithCrop = height * (1 - top - bottom);
      const aspectRatioWithCrop = widthWithCrop / heightWithCrop;

      return {
        width: Math.round(widthWithCrop),
        height: Math.round(heightWithCrop),
        aspectRatio: aspectRatioWithCrop,
      };
    }

    return { width, height, aspectRatio };
  } catch (e) {
    console.error(e);
    return fallbackValues;
  }
}
